import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "../styles/header.css"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle, categories }) => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-custom" style={{ backgroundColor: 'white' }}>
        <div className="container justify-content-between">
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false">
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link className="navbar-brand d-flex align-items-center" to="/">
            <StaticImage width={40} formats={["AUTO", "WEBP"]} placeholder="none" src="../images/codingsamples@x1.png" alt="Codingsamples logo" />
            {/* <span className="title ms-2">{siteTitle}</span> */}
          </Link>
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Offcanvas</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body justify-content-center">
              <ul className="navbar-nav pe-3">
                {categories.allStrapiCategory.edges.map(category => (
                  <li className="nav-item category-name" key={category.node.id}>
                    <Link className="nav-link active px-3" aria-current="page" to={`/category/${category.node.Slug}`}>
                      {category.node.Name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
  categories: PropTypes.any
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
